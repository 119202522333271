import { isDefined } from "@clipboard-health/util-ts";
import { CbhFeatureFlag, useCbhFlag } from "@src/appV2/FeatureFlags";
import { logEvent } from "@src/appV2/lib/analytics";
import { quizWorkersOnWorkplaceRulesFeatureFlagSchema } from "@src/appV2/WorkplaceQuiz/types";
import { USER_EVENTS } from "@src/constants";

interface UseIsQuizWorkersOnWorkplaceRulesFeatureEnabledProps {
  workplace: {
    id?: string;
    msa?: string;
    state?: string;
    quizWorkersOnRules?: boolean;
  };
}

/**
 * The decision to enable the quiz feature depends on two factors:
 * 1. A feature flag
 * 2. A property of the workplace obtained from the database
 *
 * The feature flag provides controlled rollout, allowing us to selectively enable
 * the feature for specific workplaces, states, or metropolitan statistical areas (MSAs).
 *
 * The property serves as a toggle for workplaces. If set to `true`, the workplace has
 * chosen to enable quiz functionality for its workers. If set to `false` (or left undefined),
 * the workplace does not wish to utilize the quiz feature.
 *
 * This hook evaluates both factors. In the event of a global rollout, we can discard
 * this hook and simply assess the property using `Boolean(workplace.quizWorkersOnRules)`.
 */
export function useIsQuizWorkersOnWorkplaceRulesFeatureEnabled(
  parameters: UseIsQuizWorkersOnWorkplaceRulesFeatureEnabledProps
): boolean {
  const { workplace } = parameters;

  const quizWorkersOnWorkplaceRulesFeatureFlag = useCbhFlag(
    CbhFeatureFlag.ROLLOUT_QUIZ_WORKERS_ON_WORKPLACE_RULES,
    {
      defaultValue: {
        global: false,
      },
    }
  );

  if (!workplace.quizWorkersOnRules) {
    return false;
  }

  try {
    quizWorkersOnWorkplaceRulesFeatureFlagSchema.parse(quizWorkersOnWorkplaceRulesFeatureFlag);
  } catch {
    logEvent(USER_EVENTS.QUIZ_WORKER_ON_WORKPLACE_RULES_FEATURE_FLAG_EVALUATION_ERROR, {
      parameters,
    });
    return false;
  }

  const { global, ids, msas, states } = quizWorkersOnWorkplaceRulesFeatureFlag;

  const isRolledOutGlobally = Boolean(global);
  const isRolledOutForWorkplace =
    isDefined(ids) && isDefined(workplace.id) && ids.includes(workplace.id);
  const isRolledOutForMsa =
    isDefined(msas) && isDefined(workplace.msa) && msas.includes(workplace.msa);
  const isRolledOutState =
    isDefined(states) && isDefined(workplace.state) && states.includes(workplace.state);
  return isRolledOutGlobally || isRolledOutForWorkplace || isRolledOutForMsa || isRolledOutState;
}
