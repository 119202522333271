import { type UseModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { LegacyTwoButtonDialogActions } from "@src/appV2/lib/Dialogs/LegacyDialogActions";

interface QuizFailedDialogProps {
  onCancel: () => void;
  onRetakeQuiz: () => void;
  facilityName: string;
  modalState: UseModalState;
}

export function QuizFailedDialog(props: QuizFailedDialogProps) {
  const { onCancel, onRetakeQuiz, facilityName, modalState } = props;

  const { modalIsOpen, closeModal } = modalState;

  return (
    <Dialog open={modalIsOpen} onClose={closeModal}>
      <DialogTitle>You didn&apos;t pass the quiz</DialogTitle>
      <DialogContent sx={{ textAlign: "center" }}>
        <DialogContentText>
          Unfortunately, you didn&apos;t answer enough questions correctly to pass the quiz and book
          shifts at {facilityName}.
        </DialogContentText>
        <br />
        <DialogContentText>
          You can retake the quiz to book this shift. Once you pass the quiz you won&apos;t need to
          take it again unless the information changes.
        </DialogContentText>
      </DialogContent>
      <LegacyTwoButtonDialogActions
        leftAction={<Button onClick={onCancel}>Cancel</Button>}
        rightAction={<Button onClick={onRetakeQuiz}>Retake Quiz</Button>}
      />
    </Dialog>
  );
}
